export const useMessage = () => {

  const message = useState('message', () => { return {}; });

  const show = (text: String, icon: String, isError: boolean, isWarning: boolean, buttonText: String, autoHideSeconds: Number | undefined) => {

    if (message.value.timer) {
      clearTimeout(message.value.timer);
    }

    message.value = {
      text: text,
      icon: icon ?? (isError ? 'pozor' : (isWarning ? 'pozor' : 'inf')),
      type: isError ? 'bg-error' : (isWarning ? 'bg-warning' : 'bg-success'),
      buttonText: buttonText,
      show: true,
      timer: autoHideSeconds ? setTimeout(() => hide(), autoHideSeconds * 1000) : null
    };
  }

  const hide = () => {
    message.value.show = false;

    clearTimeout(message.value.timer);
  }

  return {
    message,
    show,
    hide
  }
};